<script>
import { userService } from "@/services/user.service";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      referrals: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      fields: [
        "#",
        {
          key: "created_at",
          formatter: (value) => {
            return this.$options.filters.dateFormat(value);
          },
        },
        {
          key: "firstname",
          sortable: true,
          label: "Affiliate Name",
          formatter: (value, key, row) => {
            return row.firstname ? row.firstname + " " + row.lastname : "NA";
          },
        },
        'username',
        // { key: "lands_sold", sortable: true, label: "No of Lands Sold" },
        {
          key: "referrer_username",
          label: "Referred By",
          sortable: true,
          formatter: (value) => {
            return value == this.username ? "@Me" : value;
          },
        },
        // {
        //   key: "affiliate_signup_commission_percent",
        //   sortable: true,
        //   label: "Commission %",
        //   formatter: (value, key, row) => {
        //     // no commission from indirect referrals
        //     return row.referrer_username == this.username ? value : "NA";
        //   },
        // },
        {
          key: "referrer_signup_earning",
          sortable: true,
          label: "Registration Commission",
          formatter: (value, key, row) => {
            // no commission for indirect referrers
            return row.referrer_username == this.username ? this.$options.filters.currencyFormat(row.amount) : "NA";
          },
        },
        {
          key: "payment_date",
          sortable: true,
          label: "Status",
          formatter: (value) => {
            return value ? "activated" : "pending";
          },
        },
        // { key: "action" }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.referrals.length;
    },
    username() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.username
        : null;
    },
  },
  mounted() {
    this.getReferrals();
  },
  methods: {
    async getReferrals() {
      try {
        this.isBusy = true;
        let response = await userService.getReferrals();
        this.referrals = response.data.data;
        this.totalRows = this.referrals.length;
        this.isBusy = false;
        console.log(response);
      } catch (error) {
        this.isBusy = false;
        alert(error);
        console.log(error);
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white"> -->
      <!-- <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template> -->
      <!-- item-->
      <!-- <b-dropdown-item>Sales Report</b-dropdown-item> -->
      <!-- item-->
      <!-- <b-dropdown-item>Export Report</b-dropdown-item> -->
      <!-- item-->
      <!-- <b-dropdown-item>Profit</b-dropdown-item> -->
      <!-- item-->
      <!-- <b-dropdown-item>Action</b-dropdown-item> -->
      <!-- </b-dropdown> -->

      <h4 class="card-title mb-4">All Refferals</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="referrals"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> &nbsp; Loading...</strong>
            </div>
          </template>

          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(affiliate_signup_commission_percent)="data">
            {{
              data.item.referrer_signup_earning
                ? data.item.referrer_signup_earning.rate + "%"
                : "NA"
            }}
          </template>
<!-- 
          <template #cell(affiliate_signup_commission)="data">
            {{
              data.item.referrer_signup_earning
                ? data.item.referrer_username != username ? 'NA' : data.item.referrer_signup_earning.amount
                : 0 | currencyFormat
            }}
          </template> -->

          <template v-slot:cell(payment_date)="row">
            <div
              class="badge font-size-12"
              :class="{
                'badge-soft-success': `${row.value}` === 'activated',
                'badge-soft-warning': `${row.value}` === 'pending',
              }"
            >
              {{ row.value }}
            </div>
          </template>

          <template v-slot:cell(action)>
            <a
              href="javascript:void(0);"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a
              href="javascript:void(0);"
              class="text-danger"
              v-b-tooltip.hover
              title="Delete"
            >
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>