<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// import Transaction from './direct-referrals';
import Referrals from './referrals';
// import Indirect from './indirect-referrals';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Referrals,
    // Indirect
  },
  data() {
    return {
      copiedAffiliateLink: false,
      copiedLandSalesLink: false,
      title: "My Referrals"
    };
  },
  computed: {
    affiliateRegistrationLink () {
      return this.$store.state.auth.user.username != undefined ? window.location.origin + '/register/' + this.$store.state.auth.user.username : '...';
    },
    landSalesRegistrationLink () {
      return this.$store.state.auth.user.username != undefined ? window.location.origin + '/buyland/' + this.$store.state.auth.user.username : '...';
    }
  },
  methods: {
    copyAffiliateRegistrationLink () {
      navigator.clipboard.writeText(this.affiliateRegistrationLink);
        this.copiedAffiliateLink = true;
        setTimeout(() => {
            this.copiedAffiliateLink = false;
        }, 2000);
    },
    copyLandSalesRegistrationLink () {
      navigator.clipboard.writeText(this.landSalesRegistrationLink);
        this.copiedLandSalesLink = true;
        setTimeout(() => {
            this.copiedLandSalesLink = false;
        }, 2000);
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <!-- <div class="col-xl-12"> -->
        <!-- <Stat /> -->
        <!-- <RevenueAnalytics /> -->
        
        <div class="col-sm-12 col-md-6">
          <b-card style="background: #444444" class="text-white-50">
            <div class="card-body" >
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-16 mb-0 text-white">Affiliate Registration Link:</p>
                  <h2 class="mb-0 text-white font-size-18">{{ affiliateRegistrationLink }}</h2>
                </div>
                <div class="text-primary">
                  <span v-if="copiedAffiliateLink" class="text-white" style="font-size: 18px;">Copied!</span>
                  <i v-else class="ri-file-copy-line" data-toggle="tooltip" title="click to copy link" style="font-size: 30px; color: white; cursor: pointer;" v-on:click="copyAffiliateRegistrationLink()"></i>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-card style="background: #808080" class="text-white-50">
            <div class="card-body" >
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-16 mb-0 text-white">Land Sales Registration Link:</p>
                  <h2 class="mb-0 text-white font-size-18">{{ landSalesRegistrationLink }}</h2>
                </div>
                <div class="text-primary">
                  <span v-if="copiedLandSalesLink" class="text-white" style="font-size: 18px;">Copied!</span>
                  <i v-else class="ri-file-copy-line" data-toggle="tooltip" title="click to copy link" style="font-size: 30px; color: white; cursor: pointer;" v-on:click="copyLandSalesRegistrationLink()"></i>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      <!-- </div> -->
      <div class="col-xl-4">
        <!-- <SalesAnalytics /> -->
        <!-- <EarningReport /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <!-- <Sources /> -->
      </div>
      <div class="col-lg-4">
        <!-- <RecentActivity /> -->
      </div>
      <div class="col-lg-4">
        <!-- <RevenueLocation /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <!-- <Chat /> -->
      </div>
      <div class="col-lg-12">
        <Referrals />
        <!-- <Indirect /> -->
      </div>
    </div>
    
  </Layout>
</template>